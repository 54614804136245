import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { SharedDirectivesModule } from 'src/directives/shared-directives.module';

import { TiptapEditorComponent } from '../tiptap-editor/tiptap-editor.component.js';

import { CustomFieldsComponent } from './custom-fields.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, NgxTippyModule, SharedDirectivesModule, TiptapEditorComponent],
  declarations: [CustomFieldsComponent],
  exports: [CustomFieldsComponent],
})
export class CustomFieldsModule {}
